.features-section {
  text-align: center;
  padding: 60px 0;
}

.feature-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.feature-card {
  width: 250px;
  padding: 20px;
  margin: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  z-index: -999;
}

.feature-card:hover {
  transform: scale(2.05);
  background-color: gold;
  color: aliceblue;
  cursor: pointer;
}

.feature-icon {
  font-size: 3rem;
  margin-bottom: 10px;
}

.feature-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.feature-card p {
  font-size: 1rem;
}
.tittle {
  color: #ffd700;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 50px;
}
