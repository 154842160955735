/* HowItWorks.css */

.howItWorks {
    background-color: #f5f5f5;
    padding: 80px 0;
  }

  .howItWorksContent {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }

  h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 40px;
  }

  .step-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
  }

  .step {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    width: calc(50% - 20px); /* Adjust the width as needed based on the number of steps */
    box-sizing: border-box;
  }
.tittle{
  color: #ffd700;

}

  .stepNumber {
    font-size: 2rem;
    font-weight: bold;
    background-color: #4682b4;
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }

  .stepContent {
    text-align: left;
  }

  h3 {
    font-size: 2rem;
    margin-bottom: 10px;
  }

  .stepImage {
    max-width: 100%;
    height: auto;
    margin-top: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
 
  @media (max-width: 768px) {
    .step {
      width: calc(70% - 20px);
      align-items: center;
      text-align: center;
      padding: auto;
      margin-left: 70px;
    }
  }
