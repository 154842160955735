#nav {
  background-color: #4682b4;
  display: flex;
  flex-direction: row;
  width: 100vw;
  max-width: 100%;
  color: white;
  position: fixed;
  z-index: 9999;
}

.logo-container {
  padding: 15px;
  margin-right: auto;
}

.gitlink {
  padding: 15px;
  margin-left: auto;
}
