/* Downloads.css */

.downloads {
  text-align: center;
  padding: 80px 20px;
}

.downloadsContent {
  max-width: 600px;
  margin: 0 auto;
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

p {
  font-size: 1.2rem;
  margin-bottom: 40px;
}

.downloadWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.os {
  margin: 20px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.image {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.osName {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.osDesc {
  font-size: 1rem;
  margin-bottom: 20px;
}

.downloadButton {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.2rem;
  text-decoration: none;
  color: white;
  background-color: #61dafb;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.downloadButton:hover {
  background-color: #2196f3;
}
.tittle{
  color: #ffd700;
}

@media (max-width: 768px) {
  .os {
    width: 80%;
  }
}
