/* Home.css */

.home-container {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.hero-section {
  background-color: #4682b4;
  color: #ffd700;
  /* text-align: center; */
  padding: 120px 0;
}
/* Home.css */

.parallax-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 330px; /* Adjust as needed */
  height: 330px; /* Adjust as needed */
  z-index: 1;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.hero-content h1 {
  font-size: 50px;
  margin-bottom: 20px;
  /* margin-left: 720px; */
}

.hero-content p {
  font-size: 20px;
  margin-bottom: 40px;
  color: bisque;
}

.cta-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.2rem;
  text-decoration: none;
  color: white;
  background-color: #2c6a9e; /* Slightly lighter shade of the background color */
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.cta-button:hover {
  background-color: #2196f3;
}

.features-section {
  text-align: center;
  padding: 60px 0;
}

.feature-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

/* Add styles for feature cards */

.logoo {
  display: flex;
  position: relative;
  margin-top: -120px;
  margin-left: -320px;
}
.tee {
  font-size: 20px;
  padding: 10px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.youtubeVideo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.youtubeVideo iframe {
  aspect-ratio: 16/9;
  max-width: 700px;
  margin: 20px;
  border: none;
}

@media only screen and (max-width: 768px) {
  .logoo {
    display: none;
  }
}
