/* Contributions.css */

.contributions {
  background-color: #f5f5f5;
  padding: 60px 0;
  text-align: center;
  z-index: -9999;
}

.contributionsContent {
  max-width: 800px;
  margin: 0 auto;
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #264348; /* Dark blue-green color */
}

p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #555; /* Dark gray color */
}

.contributionStep {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 1s ease-out; /* Simple fade-in animation */
}
.contributionStep:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.contributionStep iframe {
  margin: auto;
  padding: 0%;
}

h3 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #264348;
}

.contributeIcon {
  font-size: 2rem;
  margin-right: 10px;
  color: #ffffff; /* Bright blue color */
}

.contributeLink {
  text-decoration: none;
  color: #61dafb;
  font-weight: bold;
  transition: color 0.3s ease-in-out;
}

.contributeLink:hover {
  color: #2196f3; /* Slightly darker blue on hover */
}
.tittle {
  color: #ffd700;
}
/* Contributions.css */

.button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.button a {
  color: #ffffff; /* White text color */
}

.fork-button,
.donate-button {
  background-color: #0366d6; /* GitHub blue color */
  color: #ffffff; /* White text color */
  border: 1px solid #0366d6;
}

.fork-button:hover,
.donate-button:hover {
  background-color: #0645ad; /* Darker blue on hover */
}

/* Keyframes for fade-in animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@media (max-width: 768px) {
  .contributionStep {
    width: calc(70% - 20px);
    align-items: center;
    text-align: center;
    padding: auto;
    margin-left: 50px;
  }
  .contributionStep .star {
    align-items: center;
    text-align: center;
    margin-left: 10px;
    width: 80px;
    height: 80px;
    font-size: 20px;
  }
  .content {
    font-size: 20px;
    padding: 0 20px;
  }
}
